@import "variables";
@import "bootstrap.min";





@import "Droid_Serif";
@import "Roboto_Slab";
@import "agency";

body {
  font-family: $font-primary;
}

.PageNavigation a {
  float: left;
}

.modulos-item .modulos-link .modulos-hover {
  background: rgba($primary, .9);
}



.timeline:before {
  left: 40px;
  margin-left: -1.5px;
}

.timeline>li .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline>li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li .timeline-image {
  left: 0;
  margin-left: 0;
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 20px 0 100px;
    text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

@media(min-width:768px) {
  

  .timeline:before {
    left: 50%;
  }

  .timeline>li .timeline-panel {
    float: left;
    text-align: right;
  }

  .timeline>li .timeline-image {
    left: 50%;
    margin-left: -50px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    text-align: left;
  }
}

@media(min-width:1200px) {
  .timeline>li .timeline-panel {
    padding: 0 20px 20px 100px;
  }

  .timeline>li .timeline-image {
    margin-left: -85px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

@media(max-width:768px) {
  .navbar-default .navbar-brand {
    
  }
}
