@font-face {
  font-family: 'Droid Serif';
  font-style: italic;
  font-weight: 400;
  src: local('Droid Serif Italic'), url(fonts/droidserif/droidserif-italic-webfont.woff2) format('woff2');
}

@font-face {
  font-family: 'Droid Serif';
  font-style: italic;
  font-weight: 700;
  src: local('Droid Serif Bold Italic'), url('fonts/droidserif/droidserif-bold-italic-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Droid Serif';
  font-style: normal;
  font-weight: 400;
  src: local('Droid Serif Regular'), url(fonts/droidserif/droidserif-regular-webfont.woff2) format('woff2');
}

@font-face {
  font-family: 'Droid Serif';
  font-style: normal;
  font-weight: 700;
  src: local('Droid Serif Bold'), url(fonts/droidserif/droidserif-bold-webfont.woff2) format('woff2');
}
